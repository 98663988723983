* {
  box-sizing: border-box;
}

html{
  background-color: #092949;

}

body{
  width: 100%;
  font-family: 'IBM Plex Mono', monospace;
}

li{
  margin-left: -40px;
}

a{
  color: #83b314;
}

a:hover{
  background-color: #DC0469;
  font-weight: bold;
  color: #B3DA58;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Leckerli One', cursive;
  color: #DC0469;
  margin: 0;
}

h1{
  font-size: 2.75rem;
  margin-bottom: 20px;
}

h2{
  font-size: 2.25rem;
}

main {
  background-color: #FFFACC;
  border-radius: 30px;
  border-color: #DC0469 /* Dogwood Rose */;
  border-style: dashed;
  border-width: 4px;
  padding: 40px;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 100px;
  font-size: 1.25rem;
}

p{
  color: #092949;
}

.contact-list{
  list-style-type: none;
  color: #DC0469;
}

.App {
  margin: 0 auto;
  max-width: 800px;

}

.App-logo{
  width: 100%;
}

.logo-wrapper{
  padding: 20px;
  padding-bottom: 0px;
  margin: auto;
  margin-top: -135px;
  max-width: 400px;
}

@media (max-width: 414px){
  main{
    font-size: .95rem;
  }
  h1{
    font-size: 2rem;
  }
  h2{
    font-size: 1.75rem;
  }
  p{
    margin-left: 0px;
  }
  .logo-wrapper{
    padding: 10px;
    margin-top: -110px;
  }
}

@media (max-width: 375px){
  .logo-wrapper{
    padding: 10px;
    margin-top: -100px;
  }
}

@media (max-width: 320px){
  .logo-wrapper{
    margin-top: -90px;
  }
}
